
<template>
   <div class="cart__item d-flex justify-content-between align-items-center cursor-pointer" @click="verCompra" title="Visualizar">
      <div class="cart__inner d-flex flex-grow-1">
         <div class="cart__details flex-grow-1">
            <h6 class="regulaLinha font-16">
               <strong :class="String(compra.status).includes('Aguardando pagamento') ? 'color-pendente' : String(compra.status).includes('Pendente entrega') ? 'color-separacao' : String(compra.status).includes('Cancelado') ? 'color-cancelado' : String(compra.status).includes('Finalizado') ? 'color-finalizado' : ''">
                  {{ compra.status }}
               </strong>
            </h6>
            <div class="cart__price">
               <div class="row m-0">
                  <div class="col-sm-6 p-0">
                     <i class="far fa-money-bill-wave-alt color-theme font-12 me-1"></i><strong class="me-1"> Valor total: </strong>
                     R$ {{ parseFloat(compra.valorTotal).toFixed(2) }}
                  </div>
                  <div class="col-sm-6 p-0">
                     <i class="far fa-wallet color-theme font-12 me-1"></i><strong class="me-1"> Forma pagamento: </strong> {{ compra.formaPgto }}
                  </div>
                  <div class="col-12 p-0">
                     <div :title="'Comprado em: '+ new Date(compra.dataCompra.year +'/'+ compra.dataCompra.monthValue +'/'+ compra.dataCompra.dayOfMonth +' '+ compra.dataCompra.hour +':'+ compra.dataCompra.minute).toLocaleString()">
                        <i class="far fa-calendar color-theme font-12 me-1"></i><strong class="me-1"> Última mov.: </strong>
                        {{ new Date(compra.dataUltMov.year +'/'+ compra.dataUltMov.monthValue +'/'+ compra.dataUltMov.dayOfMonth +' '+ compra.dataUltMov.hour +':'+ compra.dataUltMov.minute).toLocaleString() }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Compra',
   props: ['compra'],
   methods: {
      verCompra: function () {
         this.$emit('verCompra', this.compra)
      }
   }
}

</script>